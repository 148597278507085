<template>
    <div v-loading="$wait.is('cars.loading')" class="card card-f-h flex flex-col" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <CarDetailsModal
            :visible="carDetailsVisible"
            :selectedCarUuid="selectedCarUuid"
            @close="carDetailsVisible = false"
        />

        <div class="flex items-end justify-start border-b border-gray-400 mb-3 pb-3">
            <router-link :to="{ name: 'cars_all' }">
                <el-button type="primary">
                    {{ $t('cars.go_back') }}
                </el-button>
            </router-link>
        </div>

        <CarsArchivedTable
            :carsData="carsData"
            @handleInfo="showDetailsModal"
            @handleRestore="restoreCar"
            @handleDelete="deleteCar"
        />

        <NoDataInformation :data="noDataInformation" waitKey="cars.loading" />
    </div>
</template>
<script>
export default {
    components: {
        CarsArchivedTable: () => import(/* webpackChunkName: "Cars/CarsAllArchivedTable" */ './components/CarsArchivedTable.vue'),
        CarDetailsModal:   () => import(/* webpackChunkName: "Cars/CarDetailsModal" */ './modals/CarDetailsModal.vue'),
    },

    data() {
        return {
            noDataInformation: false,
            carsData:          [],
            carDetailsVisible: false,
            selectedCarUuid:   '',
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('assets.cars_archived'));
        this.$store.commit('setActiveMenuItem', '12');
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('cars.loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/assets/cars/archived`);
            this.carsData = data;
            this.noDataInformation = data.length;
            this.$wait.end('cars.loading');
        },

        showDetailsModal(uuid) {
            this.selectedCarUuid = uuid;
            this.carDetailsVisible = true;
        },

        async restoreCar(uuid) {
            this.$wait.start(`loading.restore${uuid}`);
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/assets/cars/${uuid}/restore`);
                this.$notify.success({ title: this.$t('common.success') });
                this.$wait.end(`loading.restore${uuid}`);
                this.getAll();
            } catch (error) {
                this.$wait.end(`loading.restore${uuid}`);
            }
        },

        deleteCar(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/assets/cars/${uuid}/force_delete`,
                actionType: 'destroy',
            });
        },
    },
};
</script>
